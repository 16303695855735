
import QRCode from 'qrcode';
export async  function  getQrCode(url, size,qrText, color = '#000') {
    
        const canvas = await QRCode.toCanvas(url, {
            errorCorrectionLevel: 'H',
            margin: 1, // 设置padding 二维码的padding
            height: size,
            width: size,
            scal: 177,
            color: {dark: color},
            rendererOpts: {quality: 0.9}
          })
          const fontWeight='bold' // 字体 粗体 格式
          const fontSize = 22 // 字体大小
          const tb = 5 // 底部文字上下间距
          const realHeight = size + fontSize + 2*tb //实际高度
          // 画布上下文对象
          const ctx = canvas.getContext("2d");
          // 获取二维码数据
          const data = ctx.getImageData(0, 0, size, size);
          ctx.fillStyle = "#fff"
          canvas.setAttribute('height', realHeight); // 重设画布像素高度
          canvas.style.setProperty('height', realHeight + 'px'); // 重设画布实际高度
          ctx.fillRect(0, 0, size, realHeight)
          ctx.putImageData(data, 0, 0)// 填充二维码数据
          ctx.font = `${fontWeight} ${fontSize}px Arial`;
          const textWidth = ctx.measureText(qrText).width; //文字真实宽度
          const ftop = size + tb; //文字距顶部位置
          const fleft = (size - textWidth) / 2; //根据字体大小计算文字left
          const textPadding = fontSize / 2; //字体边距为字体大小的一半可以自己设置
          // 设置底部背景色
          ctx.fillStyle = "#fff";
          ctx.fillRect(0, size, size, realHeight - 2*tb);
          // 设置字体填充位置
          ctx.fillRect(
              fleft - textPadding / 2,
              ftop - textPadding / 2,
              textWidth + textPadding,
              fontSize + textPadding
          );
          ctx.textBaseline = "top"; //设置绘制文本时的文本基线。
          ctx.fillStyle = "#333";// 字体颜色
          ctx.fillText(qrText, fleft, ftop);
          return (canvas.toDataURL())
    
    

}