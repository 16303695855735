<template>
  <div>
    <div class="form-title">{{editType | typeName}}教师信息卡</div>
    <el-form size="small" inline ref="form" :model="form" label-width="auto"  >
    <el-collapse v-model="activeNames">
      <el-collapse-item title="基础信息" name="1">
        <div style="text-align:center;">
        <div style="margin:auto;">
          <el-form-item label="年份" prop="year" :rules="getRules('请选择年份')">
            <el-date-picker v-model="form.year" format="yyyy" value-format="yyyy" type="year" placeholder="请选择年份" style="width:250px"> </el-date-picker>
          </el-form-item>          
          <el-form-item label="学期" prop="semester" :rules="getRules('请选择学期')"  >
            <el-select v-model="form.semester" placeholder="请选择" style="width:250px">
              <el-option label="春季学期" value="0"></el-option>
              <el-option label="秋季学期" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="征订批次" prop="batch" :rules="getRules('请选择批次')"  >
            <el-select v-model="form.batch" placeholder="请选择" style="width:250px">
              <el-option  v-for="i in 10" :key="i" :value="i" :label="i"></el-option>             
            </el-select>
          </el-form-item>  
        </div>
        <div style="margin:auto;">         
          <el-form-item label="征订天数" prop="subscriptionDayCount" :rules="getRules('请输入征订天数')"  >
              <el-input-number v-model="form.subscriptionDayCount" :min="0" :max="150" style="width:250px"></el-input-number>
          </el-form-item> 
           <el-form-item label="征订日期" prop="addDate"   >
            <el-date-picker  v-model="addDate" type="daterange" align="right"
              unlink-panels range-separator="至" value-format="yyyy-MM-dd"
              start-placeholder="开始日期" end-placeholder="结束日期" style="width:250px" >
            </el-date-picker>
          </el-form-item>
          <el-form-item  label="显示单价" prop="isShowUnitPrice" :rules="getRules('请选择是否显示单价')"  >
              <el-select v-model="form.isShowUnitPrice" placeholder="请选择" style="width:250px">
                <el-option label="不显示" value="0"></el-option>
                <el-option label="显示" value="1"></el-option>
              </el-select>
          </el-form-item>
        </div>
        <div style="margin:auto;">     
            <el-form-item label="编码" prop="code" :rules="getRules('请输入编码')"  >
              <el-input v-model="form.code" maxlength="50" placeholder="请输入编码" :disabled="disabled" style="width:250px"></el-input>
          </el-form-item>     
          <el-form-item label="配送方式" prop="deliverMode" >
              <el-input v-model="form.deliverMode" maxlength="50" placeholder="请输入配送方式" :disabled="disabled" style="width:250px"></el-input>
          </el-form-item>         
          <el-form-item label="温馨提示" prop="reminder" >
              <el-input v-model="form.reminder" maxlength="50" placeholder="请输入温馨提示" :disabled="disabled" style="width:250px"></el-input>
          </el-form-item>  
        </div>
        <div style="margin:auto;">       
             <el-form-item label="征订须知" prop="notice" :rules="getRules('请设置征订须知')" >
              <el-button @click="isShowNotice=true" type="primary" plain size="mini" style="width:250px">设置</el-button>
          </el-form-item> 
          <el-form-item label="显示形式" prop="noticeType" :rules="getRules('请选择显示形式')" >
              <el-select v-model="form.noticeType" placeholder="请选择" style="width:250px">
                <el-option label="常规样式" value="1"></el-option>
                <el-option label="弹出式" value="2"></el-option>
              </el-select>
          </el-form-item> 
           <el-form-item label="备注" prop="remark" >
              <el-input v-model="form.remark" type="textarea" maxlength="50" placeholder="请输入备注" :disabled="disabled" style="width:250px"></el-input>
          </el-form-item>   
        </div>
        <div style="width:1020px;text-align:left;margin:auto;"> 
          <el-form-item label="学校" prop="selectedSchoolInfoList"  >
            <el-button type="primary" icon="el-icon-search" @click="showSelectSchool(true)" v-if="editType=='add'">选择学校</el-button>
            <el-button v-if="editType!='add'" style="margin-top:5px;" type="primary" plain size="mini" >
            {{form.school?form.school.schoolName:''}}
          </el-button>
          </el-form-item>
          
        </div>
        <div style="width:1020px;text-align:left;margin:auto;padding-left:150px;" v-if="editType=='add'">
          <el-button style="margin-top:5px;" type="primary" plain size="mini" v-for="(schoolInfo,index) in selectedSchoolInfoList" :key="schoolInfo.id" @click="removeSchool(index)">
            {{schoolInfo.name}}<i class="el-icon-delete el-icon--right"></i>
          </el-button>
        </div>    

        </div>
      </el-collapse-item>

      <el-collapse-item title="套餐信息" name="1">
        <div style="text-align:center">
          <el-button type="primary" icon="el-icon-search" v-if="editType!='show'" @click="showSelectPackage(true)">选择套餐</el-button>
        </div>
        <div style="width:780px; text-align:left;margin:auto;">
          
          <div class="package-box" v-for="(packageInfo,index) in selectedPackageList" :key="packageInfo.id">
            <div class="box-left">
              <div class="package-info" >              
                <div class="package-img" title="点击查看大图"><el-image style="width:50px;height:50px;" :src="getFirstFileByList(packageInfo.thumbnailList).url"  @click.stop="showBigImage(getFirstFileByList(packageInfo.thumbnailList).url)"></el-image></div>
                <div class="item" :title="packageInfo.name">套餐名称：{{packageInfo.name}}</div>
                <div class="item">套餐单价：{{packageInfo.unitPrice}}元</div>
                <div class="item">套餐优惠价：{{packageInfo.favorablePrice}}元</div>
              </div>
              <div class="combination-box">
                <div class="combination-info" v-for="combinationInfo in packageInfo.combinationList" :key="combinationInfo.id">
                  <div class="combination-img"><el-image style="width:50px;height:50px;" :src="getFirstFileByList(combinationInfo.thumbnailList).url"   @click.stop="showBigImage(getFirstFileByList(combinationInfo.thumbnailList).url)"></el-image></div>
                  <div class="item" :title="combinationInfo.name">组合名称：{{combinationInfo.name}}</div>
                  <div class="item">组合单价：{{combinationInfo.unitPrice}}元</div>
                  <div class="item">组合优惠价：{{combinationInfo.favorablePrice}}元</div>
                </div> 

              </div>
            </div>
            <div class="box-price">
              <div class="price">
                <span class="new-price">  ￥{{packageInfo.favorablePrice | multiplyWithPrecision(form.subscriptionDayCount)}} </span><br />
                <span class="old-price">  ￥{{packageInfo.unitPrice | multiplyWithPrecision(form.subscriptionDayCount)}} </span>
              </div>

            </div>
            <div class="box-right">
              <div class="btn">
              <el-button type="danger" title="移除" v-if="editType!='show'" size="small" icon="el-icon-delete" circle @click="removePackage(index)"></el-button>
              </div>
            </div>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="btn-box">
      <el-button @click="cancel">返回</el-button>
      <el-button type="primary" v-debounce="submit" v-if="editType!='show'" >保存</el-button>
    </div>

    </el-form>


    <SelectSchool v-if="isShowSelectSchoolModal" v-model="isShowSelectSchoolModal" @okSelected="okSelectedSchool"  ></SelectSchool>
    <SelectPackage v-if="isShowSelectPackageModal" v-model="isShowSelectPackageModal" @okSelected="okSelectedPackage"  ></SelectPackage>
    <el-image-viewer v-if="imgDialogVisible" :on-close="onClose" :url-list="[bigImageUrl]" />
    <Notice v-if="isShowNotice" v-model="isShowNotice" @change="setNotice" :notice="form.notice" :edit="true" ></Notice>
  </div>
</template>

<script>
import { addTeachCardInfo, updateTeachCardInfo,getTeachCardInfo,getDefaultRemind } from '@/api/index.js'
import UploadImg from '@/components/UploadImg/index.vue'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import SelectSchool from '../../components/SelectSchool'
import SelectPackage from '../../components/SelectPackage'
import Notice from '../../components/Notice'
export default {
  name: 'CustomerForm',
  components: { UploadImg,ElImageViewer,SelectSchool,SelectPackage ,Notice},
  props: ['value', 'editId', 'editType'],
  data() {
    return {
      activeNames: ['1', '2'],
      form:{
        id:'',
        year:'',
        semester:'',
        subscriptionTypeId:'2',
        batch:'',
        effectiveEndTime:'',
        effectiveStartTime:'',
        isShowUnitPrice:'',
        subscriptionDayCount:'',
        remark:'',
        reminder:'',
        deliverMode:'',
        code:'',
        packageList:[],
        schoolIdsList:[],
        notice:'',
        noticeType:'',

      },
      isShowNotice:false,
      disabled:false,
      imgDialogVisible:false,//图片预览
      bigImageUrl:'',
      addDate:[],
      isShowSelectSchoolModal:false,//选择学校弹框控制
      selectedSchoolInfoList:[],//已选择的学校
      isShowSelectPackageModal:false,//选择套餐弹框控制
      selectedPackageList:[],//已选择的套餐
    }
  },
  created() {
    this.disabled = this.editType=='show';
    if (this.editType !='add' ) {
      this.form.id = this.editId;
      this.getStuCardInfo();
    }else{
      this.getDefaultRemind();
    }
  },
  mounted() {
    
  },
  methods: {
     async getDefaultRemind(){
      let param = {
        type:'teacher'
      };      
      const res = await getDefaultRemind(param)
      if (res.code != 200) return
    	this.form.notice = res.data;  	
    },
    getRules(message){
      if(this.disabled){
        return [];
      }
      return [{ required: true, message: message, trigger: 'blur' }];
    },
    //返回
    cancel() {
      this.$emit('input', 0)
      this.$emit('changeShowType', 'list')
    },
    //查看大图
    showBigImage(url){
      this.imgDialogVisible = true;
      this.bigImageUrl = url;
    },
    //关闭大图
    onClose() {
      this.imgDialogVisible = false
    },
    getFirstFileByList(list){
      if(list && list.length>0){
        return list[0];
      }
      return {};
    },
    removePackage(index){
      this.selectedPackageList.splice(index,1)
    },
    okSelectedSchool(schoolList){//选择学校回调      
      //以下为去重处理
      let allList = this.selectedSchoolInfoList.concat(schoolList);
      let newList = [];
      for(let item1 of allList){
        let flag = true;
        for(let item2 of newList){
          if(item1.id == item2.id){
            flag = false;
          }
        }
        if(flag){
          newList.push(item1)
        }
      }
      this.selectedSchoolInfoList = newList;
      this.isShowSelectSchoolModal = false;
    },
    
    removeSchool(index){//移除学校
      this.selectedSchoolInfoList.splice(index,1);
    },
    showSelectSchool(type){//选择学校弹框
      this.isShowSelectSchoolModal = type;
    },
    showSelectPackage(type){//选择套餐
      this.isShowSelectPackageModal = type;
    },
    okSelectedPackage(packageList){//选择套餐回调      
      
      //以下为去重处理
      let allList = this.selectedPackageList.concat(packageList);
      let newList = [];
      for(let item1 of allList){
        let flag = true;
        for(let item2 of newList){
          if(item1.id == item2.id){
            flag = false;
          }
        }
        if(flag){
          newList.push(item1)
        }
      }
      this.selectedPackageList = newList;
      this.isShowSelectPackageModal = false;
    },
    //编辑获取详情
    getStuCardInfo(){
      let param = {id:this.editId};      
      getTeachCardInfo(param).then(res => {
        if (res.code != 200) return
        this.form = res.data;      
        this.addDate = [this.form.effectiveStartTime,this.form.effectiveEndTime]  
        this.selectedSchoolInfoList = [{
          name:this.form.school.schoolName,
          id:this.form.school.id,
          originalId:this.form.school.id,
        }];
        this.selectedPackageList = this.form.packageList;
        
      })
    },
    submit() {      
     if (!this.$validate('form')) return
     if(this.selectedSchoolInfoList.length==0){
        this.$message.error('请选择学校')
        return;
      }
      if(this.addDate.length==0){
        this.$message.error('请设置征订日期')
        return;
      }
      if(this.selectedPackageList.length==0){
        this.$message.error('请选择套餐')
        return;
      }

      this.form.effectiveStartTime = this.addDate[0];
      this.form.effectiveEndTime = this.addDate[1];

      if(this.editType == 'add'){
        this.form.schoolIdsList = this.selectedSchoolInfoList.map(item=>{
          return item.id;
        });

        this.form.packageList= this.selectedPackageList.map(item=>{
        let newItem = {
          originalId:item.id,
          favorablePrice:item.favorablePrice,
          unitPrice:item.unitPrice,
          remark:item.remark,
          name:item.name
        }
        return newItem;
      })


      }else{
        this.form.schoolId = this.form.school.originalId;

        this.form.packageList= this.selectedPackageList.map(item=>{
        let newItem = {
          originalId:item.originalId,
          favorablePrice:item.favorablePrice,
          unitPrice:item.unitPrice,
          remark:item.remark,
          name:item.name
        }
        return newItem;
      })
      }

      

      let form = JSON.parse(JSON.stringify(this.form));
      let success = res => {
        if (res.code != 200) {
          this.$message.error(res.msg || '操作失败')
          return
        }
        this.$message.success('操作成功')
        this.cancel()
      }
      if (this.editType == 'add') {
        addTeachCardInfo(form).then(success)
      } else {
        updateTeachCardInfo(form).then(success)
      }
    },
  },
}
</script>

<style scoped>

/**编辑套餐信息中的组合列表样式 */
.package-box{
  width:860px; 
  display: flex; 
  border: 2px solid #C9E3FB;
  border-radius: 5px;
  margin-top:10px;
}
.package-box .box-left{
  flex: 0 0 700px;
  border-right: 1px solid #C9E3FB;
}
.package-box .box-left .package-info{
  display: flex;
  border-bottom: 1px solid #C9E3FB;
  line-height: 70px;
  height: 70px;
}
.package-box .box-left .package-info .package-img{
  flex: 0 0 60px;
  width: 60px;
  height: 50px;
  margin: auto;
  cursor: pointer;
  padding-left: 5px;
}
.package-box .box-left .package-info .item{
  flex: 1;
  text-align: left;
  line-height: 70px;
  padding-left:10px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}
.package-box .box-left .combination-box{
  padding-left:50px;
}
.package-box .box-left .combination-box .combination-info{
  display: flex;
  border-left: 1px solid #C9E3FB;
  line-height: 60px;
  height: 60px;
}

.package-box .box-left .combination-box .combination-info:not(:last-child){
  border-bottom: 1px solid #C9E3FB;
}


.package-box .box-left .combination-box .combination-info .combination-img{
  flex: 0 0 60px;
  width: 60px;
  height: 50px;
  cursor: pointer;
  margin: auto;
  padding-left: 5px;
}
.package-box .box-left .combination-box .combination-info .item{
  flex: 1;
  text-align: left;
  line-height: 60px;
  padding-left:10px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow:ellipsis;
}

.package-box .box-price{
  flex: 0 0 100px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #C9E3FB;
}

.package-box .box-price .price{
  margin: auto;
  text-align: center;
  padding-top:5px;
}

.package-box .box-price .price .new-price{
  margin: auto;
  text-align: center;
  padding-top:5px;
  font-size:16px;
  color:red;
}

.package-box .box-price .price .old-price{
  text-decoration:line-through;
}



.package-box .box-right{
  flex: 1;
  display: flex;
  flex-direction: column;
}
.package-box .box-right .btn{
  margin: auto;
  text-align: center;
  padding-top:5px;
}

</style>
