<template>
  <div style="width:100%">
    <TeachCardList v-if="editType == 'list'"  @changeShowType="changeShowType"></TeachCardList>
    <TeachCardForm v-if="editType == 'edit' || editType == 'add'" :editId="editId"  :editType="editType"  @changeShowType="changeShowType"></TeachCardForm>
    <TeachCardInfo v-if="editType == 'show'" :editId="editId"    @changeShowType="changeShowType"></TeachCardInfo>
  </div>
</template>

<script>
import TeachCardList from './components/TeachCardList'
import TeachCardForm from './components/TeachCardForm'
import TeachCardInfo from './components/TeachCardInfo'

export default {
  name: 'teachCard',
  components: { TeachCardForm,TeachCardList,TeachCardInfo },
  data() {
    return {
      editType:'list',
      editId:'',
    }
    
  },
  created() {
  },
  methods: {
    changeShowType(type,id){
      if(type!='add'){
        this.editId = id;
      }
      this.editType = type;
    }
  }
}
</script>

<style>
</style>